<template>

  <DefaultCrudView
    @create-new="newFilter"
    :breadcrumbsItems="breadcrumbs"
    :actions="crudActions"
    containerMedium
  >
    <template slot="content">

      <v-data-table
        :headers="headers"
        :items="filters"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :footer-props="$defaultDataTableFooterProps"
      >
        <template v-slot:item.tenant_id="{ item }">
          {{ getTenantTitle(item.tenant_id) }}
        </template>

        <template v-slot:item.actions_key="{ item }">
          <span
            v-for="(key, index) in item.actions_key"
            :key="key"
            color="primary"
            dark
          >
            {{ key }}<span v-if="index != item.actions_key.length - 1">, </span> 
          </span>
        </template>

        <template v-slot:item.actions="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="editFilter(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>

        </template>

      </v-data-table>

      <DefaultModal :title="getModalTitle()" ref="modal" v-on:submit="save">
        <template v-slot:content>
          <DynamicFormContent :fields="fields"></DynamicFormContent>
        </template>
      </DefaultModal>

    </template>
  
  </DefaultCrudView>
</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }
</style>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import router from '@/router'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent.vue'

import FilterActions from '@/store/operation/filter/actions-types'
import FilterMutations from '@/store/operation/filter/mutations-types'
import AppActions from '@/store/app/actions-types'
import TenantActions from '@/store/core/tenant/actions-types'

export default {
  name: "FilterSettings",

  components: {
    DefaultCrudView,
    DefaultModal,
    DynamicFormContent
  },

  computed: {
    ...mapGetters('app', [
      'getIconForOperationSettingsItem'
    ]),

    ...mapState({
      fields: state => state.operation.filter.fields,
      headers: state => state.operation.filter.tableHeaders,
      selectedFilter: state => state.operation.filter.selectedFilter
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('filters'),
          prependIcon: this.getIconForOperationSettingsItem('filters'),
        },
      ]
    }
  },

  data() {
    return {
      loading: true,
      filters: [],
      options: {
        sortBy: ['filter_key'],
        sortDesc: [false],
      },
      itemsLength: 0,
      crudActions: [
        {
          text: "create_filter",
          eventToEmit: "create-new",
        }
      ],
      tenants: [],
      actions: []
    }
  },

  mounted() {
    this.[FilterMutations.SET_SELECTED_FILTER](null)

    this.[TenantActions.GET_ALL_TENANTS]()
      .then((tenants) => {
        this.[FilterMutations.SET_FIELD_TENANTS](tenants.items)
        this.tenants = tenants.items
      })
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('operation/filter', [
      FilterActions.GET_ALL_FILTERS,
      FilterActions.SAVE_FILTER
    ]),

    ...mapActions('core/tenant', [
      TenantActions.GET_ALL_TENANTS
    ]),

    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapMutations('operation/filter', [
      FilterMutations.SET_FIELD_FILTERS,
      FilterMutations.SET_SELECTED_FILTER,
      FilterMutations.SET_FIELD_TENANTS
    ]),

    newFilter() {
      this.[FilterMutations.SET_SELECTED_FILTER](null)
      this.$refs.modal.openModal()
    },

    save() {
      this.$refs.modal.errorMessage = null
      this.$refs.modal.errorList = []

      this.[FilterActions.SAVE_FILTER]()
        .then(() => {
          let message = this.selectedFilter ? 'Filter edited' : 'Filter created'
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(message))
          this.$refs.modal.closeModal()
          this.reloadData()
        })
        .catch(() => this.$refs.modal.submitting = false)
    },

    reloadData() {
      let options = {...this.$route.query}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.offset = this.options.itemsPerPage * (this.options.page - 1)
      this.loading = true
      this.[FilterActions.GET_ALL_FILTERS](options)
        .then((result) => {
          this.filters = result.items
          this.itemsLength = result.total

          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    editFilter(filter) {
      router.push({
        name: 'FilterEdit',
        params: { filterId: filter.filter_id }
      })
    },

    getTenantTitle(id) {
      let tenant = this.tenants.find(f => f.tenant_id == id)

      return tenant ? tenant.title : ''
    },

    getModalTitle() {
      return this.selectedFilter ? this.$t('edit_filter') : this.$t('new_filter')
    }
  }
}

</script>